/* CSS to handle visibility for screen and print */
@media print {
  .printable-content {
    display: block !important;
  }

  .screen-content {
    display: none !important;
  }
}
 

@media screen {
  .printable-content {
    display: none;
  }

  .screen-content {
    display: block;
  }
}

.slick-slide {
  height: 300px;
  overflow: hidden;
}

.slick-slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
  
.sidenav-container {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

@media print {
  /* body {
    visibility: hidden;
  } */

  .printableContent {
    visibility: visible;
    left: 0;
    top: 0;
    width: 100%;
    height: auto;
    margin: 0;
    padding: 0;
    overflow: visible;
  }

  /* Remove browser default headers/footers */
  @page {
    margin: 0;
  }

  /* Prevent scrollbar during print */
  .MuiBox-root {
    overflow: visible !important;
    padding: 0 !important;
    margin: 0 !important;
    box-shadow: none !important;
  }

  /* Hide buttons during print */
  .MuiButton-root, .no-print {
    display: none !important;
  }
}
/* Default styles */
.custom-input .MuiInput-underline:before {
  border-bottom-color: rgba(0, 0, 0, 0.42); /* Default border color */
}

.custom-input .MuiInput-underline:hover:before {
  border-bottom-color: black; /* Hover color */
}

.custom-input .MuiInput-underline:after {
  border-bottom-color: blue; /* Focused bottom border */
}

.custom-input .MuiFormLabel-root {
  color: rgba(0, 0, 0, 0.54); /* Default label color */
}

.custom-input .MuiFormLabel-root.Mui-focused {
  color: blue; /* Focused label color */
}

/* Error styles */
.error-field .MuiInput-underline:before {
  border-bottom-color: red;
}

.error-field .MuiInput-underline:hover:before {
  border-bottom-color: darkred; /* Keep red when hovering */
}

.error-field .MuiInput-underline:after {
  border-bottom-color: red; /* Focused red bottom border */
}

.error-field .MuiFormLabel-root {
  color: red; /* Error label color */
}

.error-field .MuiFormLabel-root.Mui-focused {
  color: red; /* Focused error label color */
}

input, textarea, select {
  font-size: 16px;
}

*:focus {
  outline: none;
  box-shadow: none;
}

/* Make the app responsive across all browsers on iPhone */
html, body, #root {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 768px) {
  /* Adjust styles for mobile view */
  #root {
      padding: 10px;
  }
}

/* Fix viewport height issue on iPhone browsers */
.full-height {
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
}

#root::-webkit-scrollbar {
  display: none;
}
::-webkit-scrollbar{
  -webkit-appearance: none;
  width: 7px;
  height: 7px;
}
::-webkit-scrollbar-thumb{
  border-radius: 4px;
  background-color: rgba(0, 0, 0, .5);
  box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}